/* general */

html {
  height: 100%;
  margin: 0 auto;
  padding: 0;
}

body {
  text-align: center;
  color: white;
  margin: 0 auto;
  overflow-x: hidden;
  height: 100%;
}

a {
  font-weight: 600;
  color: #fff;
}

h1 {
  font-size: 36px;
  margin: 0px auto 10px auto;
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 800;
}

h2 {
  font-size: 28px;
  line-height: 1.4;
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 350;
  padding: 0px 0px;
  margin: 0px auto 25px auto;
}

p {
  font-size: 24px;
  line-height: 1.4;
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  margin: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: center;
}

li {
  display: inline;
  font-family: 'Lato', sans-serif;
}

li.menu {
  font-size: 24px;
  text-decoration: underline;
}

li a {
  display: inline-block;
  padding: 25px;
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

/* classes */

.aligndiv {
  width: 800px;
  max-width: 94%;
  position: absolute;
  bottom: 120px; right: 0; left: 150px;
  overflow: auto;
  margin: auto;
  text-align: left;
  -webkit-animation: fadeInUpward 1s;
  animation: fadeInUpward 1s;
  -moz-animation: fadeInUpward 1s;
  -o-animation: fadeInUpward 1s;
}

.aligndivcenter {
  display: inline-block;
  width: 800px;
  max-width: 94%;
  position: absolute;
  right: 0; left: 0; top: 15%; bottom: 0.1%;
  overflow: auto;
  margin: auto;
  text-align: center;
  -webkit-animation: fadeInUpward 1s;
  animation: fadeInUpward 1s;
  -moz-animation: fadeInUpward 1s;
  -o-animation: fadeInUpward 1s;
}

.main {
  height: auto !important;
  min-height: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  display: block;
  overflow: hidden;
  background: #404040 url("./images/space.jpg") no-repeat center bottom fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
  }

.menu {
  top: 0px;
  display: inline-block;
  min-height: 10%;
}

/* IDs */

#beppe {
  max-height: 90%;
  border-radius: 3em;
}

#profileph {
  margin-bottom: 5px;
  width:150px;
  height: 150px;
  -moz-border-radius:75px;
  -webkit-border-radius: 75px;
  border-radius: 75px;
  -khtml-border-radius: 75px;
  -webkit-appearance: none;
}

#rocket {
  margin-left: 50px;
  width: 70px;
  height: 70px;
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

/* Links */

.links a{
  display:inline-block;
  transition:transform 0.125s cubic-bezier(0.5, 0, 0.25, 2);
  font-style:normal;margin:0 10px
}

.links a:hover{
  transform:scale(1.30)
}

.links a svg{width:30px}.only-mobile{display:none}

.links a svg{width:50px}.only-mobile{display:block}

#link:visited {
  color: #fff;
  opacity: 0.65;
  text-decoration: none;
  border-bottom: 2px solid #fff;
}

#link:hover {
  color: #fff;
  opacity: 1.0;
}

/* Buttons */

a.button{
  display: inline-block;
  padding: 0.5em 1.5em;
  border: 0.15em solid #FFFFFF;
  margin: 0 0.3em 0.3em 0;
  border-radius: 1em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Lato',sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  transition: all 0.2s;
  max-width: 40%;
}

a.button:hover{
  color: #000000;
  background-color: #FFFFFF;
}

@media all and (max-width:30em){
  a.button{
  display: block;
  margin: 0.4em auto;
  }
}

/* Animations */

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
  }

.bouncy{
  animation:bouncy 3s infinite linear;
  position:relative;
}
  
@keyframes bouncy {
  0% { top:0em }
  40% { top:0em }
  43% { top:-0.9em }
  46% { top:0em }
  48% { top:-0.4em }
  50% { top:0em }
  100% {top:0em; }
}

  @-webkit-keyframes fadeInUpward {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }
  
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
  }
  
  @keyframes fadeInUpward {
    0% {
        opacity: 0;
        -webkit-transform: translateY(50px);
        -ms-transform: translateY(50px);
        transform: translateY(50px);
    }
  
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
  }
  
  @media (max-height: 550px) {
    .main {
        min-height: 400pt;
        height: auto;
    }
  
  }
  
  @media (max-width: 768px) {
  
    .aligndiv {
        bottom: 50px;
        left: 16px;
    }
  
    h1 {
        font-size: 24px;
    }
  
    h2 {
        font-size: 18px;
    }
  }
